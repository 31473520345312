<template>
  <PageLoader v-if="pageLoading" :loading="pageLoading" />

  <div v-else class="container_margin">
    <b-modal
      id="export-show-all-pre"
      size="lg"
      centered
      hide-footer
      hide-header
    >
      <h5 class="text-blue"><b>{{ "Precedents" }}</b></h5>

      <div class="card p-2 popUp_card">
        <ol>
          <li class="font-14 my-1"
            v-for="(pre, index) in precedents"
            :key="'precedents_all' + index"
          >{{ pre }}</li>
        </ol>
      </div>
    </b-modal>

    <div class="card border shadow-sm p-3 rounded">
      <template v-if="topic">
        <h5 class="text-blue"><b>{{ "Topic" }}</b></h5>
        <p>{{ topic }}</p>
      </template>

      <h5 class="text-blue"><b>{{ "Judgement Info" }}</b></h5>

      <div
        class="row"
        v-for="(row, r_id) in [[
            ['Decision Date', $options.filters.formatDateLoc(decDate)],
            ['Petitioner', case_info.petitioner],
            ['Respondent', case_info.respondent],
          ],
          [
            ['Case Number', case_info.case_number],
            ['Petitioner\'s Case', case_info.petitioner_case],
            ['Respondent\'s Claim', case_info.respondent_claims],
          ],
          [
            ['Court', case_info.court],
            ['Judgement', case_info.judgement],
        ]]"
        :key="`inforow-${r_id}`"
      >
        <div
          v-for="(info, i_id) in row"
          :key="`info-${i_id}`"
          :class="[
            'col-12 mt-2',
            `col-md-${(row.length == 3 ? [3,5,4] : [3,9])[i_id]}`
          ]">
          <b>{{ info[0] }}</b>
          <p>{{ info[1] }}</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 col-xl-9 mt-3">
        <div class="card border shadow-sm p-3 rounded h-100">
          <div class="row">
            <div class="col">
              <h5 class="font-weight-bold text-blue">
                {{ "Precedents" }}
              </h5>
            </div>

            <div class="col-5" v-if="precedents.length > 10">
              <span
                v-b-modal.export-show-all-pre
                class="text-blue float-right"
              >
                Show All
              </span>
            </div>
          </div>
          
          <div
            v-if="precedents.length == 0"
            class="w-100 d-flex flex-column"
          >
            <img
              style="width: 200px; height: auto; margin: auto; margin-bottom: 1rem;"
              src="@/assets/noData.png"
              alt="No Data Image"
            />
            <p class="text-center text-blue font-weight-bold mb-0">
              There is no precedents for this case
            </p>
          </div>

          <div v-else class="row h-100">
            <div
              class="col-sm-12 col-xl-6"
              v-for="(pre, index) in precedents.slice(0, 10)"
              :key="'precedents_' + index"
            >
              <p class="font-14">{{ index + 1 }}. {{ pre }}</p>
            </div>
          </div>

          <!-- Precedent Report Button -->
          <!-- 
            style="position: absolute; bottom: 10px; right: 10px"
           -->
          <div
            class="row mt-3"
            v-if="
              precedents.length &&
              precedentsLoader !== 'Not enough data'
            ">
            <div class="col-sm-12 col-md-6 col-xl-12 align-self-center">
              <button
                v-if="precedentsLoader >= 100 && all_precedents.length"
                @click="viewPrecedentReport"
                class="btn btn-primary float-right"
              >
                {{ "Precedent Report" }}
              </button>

              <button
                v-else-if="precedentsLoader < 100 && all_precedents.length == 0"
                class="btn btn-warning-outlined float-right"
                @click="$toast.warning('Still in progress.')"
              >
                <b>Generating Precedent Report: </b>
                {{ precedentsLoader.toFixed(2) }} %
                <b-spinner small label="Spinning" class="spinner"></b-spinner>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col mt-3">
        <div class="card border shadow-sm p-3 rounded h-100 minH">
          <h5 class="text-blue"><b>{{ "Export Summary To" }}</b></h5>

          <div class="row">
            <div
              class="col-6 col-md-12 mt-1"
              v-for="(export_item, export_item_normal) in exports_items"
              :key="'export_pdf' + export_item_normal"
            >
              <div class="d-flex align-items-center mt-1">
                <input
                  :name="'pdf' + export_item_normal"
                  :id="'pdf' + export_item_normal"
                  type="checkbox"
                  :value="export_item"
                  v-model="pdf_status"
                  class="form-check-input mb-2"
                />

                <span class="material-icons-outlined icon-export-blue mx-2">
                  {{ export_item.icon }}
                </span>

                <b class="text-blue">{{ export_item.name }}</b>
              </div>
            </div>
          </div>

          <div class="form-check form-switch mt-3">
            <input
              class="form-check-input float-right"
              type="checkbox"
              role="switch"
              v-model="status"
            />
            <label
              class="form-check-label small text-blue"
              v-b-tooltip.hover.bottom="{ variant: 'secondary' }"
              title="Click To Export with NER Tags"
            >
              {{ "Export with NER Tags" }}
            </label>
          </div>

          <div
            class="mt-4 mt-md-0"
            style="position: absolute; bottom: 1rem; right: 1rem"
          >
            <button @click="exportData" class="btn btn-primary float-right">
              {{ "Export" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="nerAccordion" class="accordion mt-3">
      <div class="card border shadow-sm py-1 px-3 rounded">
        <div
          id="nerAccordionHeading"
          class="card-header cursor-pointer"
          data-toggle="collapse"
          data-target="#nerAccordionContent"
          aria-controls="nerAccordionContent"
          aria-expanded="true"
        >
          <label class="accordion-label text-blue font-weight-bold" for="toggleFilters">
            All NER Entities [Summary]
            <span class="material-symbols-outlined float-right accordion-icon">
              expand_less
            </span>
          </label>
          <p>{{ NerTagExplanation }}</p>
        </div>

        <div
          id="nerAccordionContent"
          class="collapse"
          aria-labelledby="nerAccordionHeading"
          data-parent="#nerAccordion"
        >
          <div class="card border-blue shadow-sm p-2 rounded table-card mb-3">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" width="25%">{{ "Abbreviations" }}</th>
                  <th scope="col" width="20%">{{ "Full Form" }}</th>
                  <th scope="col" width="50%">{{ "Entities" }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in ner_final"
                  :key="'ner_list' + index"
                  :class="item.Color"
                >
                  <td width="25%">{{ item.abbreviation }}</td>
                  <td width="20%">{{ item.full_form }}</td>
                  <td width="50%">
                    <p
                      v-for="(ent, index) in item.Entities"
                      :key="'Entities' + index"
                    >
                      {{ ent }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <h4
      v-if="full_content === 'not available yet'"
      class="text-blue text-center mt-4"
    >
      <b>{{ "Case Summary" }}</b>
    </h4>

    <div class="row">
      <div class="col-12 col-md-6">
        <button
          v-if="this.full_content.type === 'html'"
          class="btn btn-primary float-right mt-3"
          @click="ExportData"
        >
          Export to PDF
        </button>
        <h4
          v-if="full_content !== 'not available yet'"
          :class="['text-blue mt-4',
            {'text-center': this.full_content.type != 'html'}
          ]"
        >
          <b>{{ "Complete Case" }}</b>
        </h4>

        <div class="card border shadow-sm p-3 rounded mt-2 key_Card">
          <template v-if="full_content === 'not available yet'">
            <b class="text-blue mt-1">{{ "Key Points" }}</b>
            <div
              class="row border-bottom m-2"
              v-for="(key_point, index) in key_points_original"
              :key="'key_points' + index"
            >
              <div class="col-1">
                <p class="ner_content float-left">
                  <b>{{ index + 1 }}</b>
                </p>
              </div>
              <div class="col">
                <p
                  v-if="checked"
                  id="ner_content"
                  class="ner_content"
                  v-html="key_points[index]"
                ></p>
                <p
                  v-else
                  class="ner_content"
                >{{ key_point }}</p>
              </div>
              <div class="col-1">
                <span
                  v-b-tooltip.hover.bottom="{ variant: 'secondary' }"
                  :title="isSpeaking ? 'Pause' : 'Audio'"
                  @click="toggleSpeech(key_points[index], index)"
                  class="float-right cursor-pointer material-symbols-outlined icon-blue"
                >
                  {{
                    buttonStates[index].isSpeaking
                      ? "pause"
                      : "text_to_speech"
                  }}
                </span>
              </div>
            </div>
          </template>

          <template v-else>
            <p
              v-if="this.full_content.type === 'html'"
              class="m-2 border-bottom mb-3"
              v-html="this.full_content.value"
            ></p>
            <iframe
              v-else-if="this.full_content.type === 'pdf'"
              class="border-bottom mb-3"
              :src="this.full_content.value"
              height="600px"
              width="100%"
              frameborder="0"
            ></iframe>

            <!-- Disclaimer -->
            <p class="font-10 text-muted">
              NexLaw does not hold any copyright for the case content. The
              content within this material, including but not limited to text,
              images, graphics, logos, audio clips, and software, belongs to
              Comonlii and is protected by copyright laws. All rights are
              reserved by Comonlii
              <em>
                (CommonLII is coordinated by AustLII, a joint facility of UTS
                and UNSW Faculties of Law, in cooperation with some of the
                members of the Free Access to Law Movement (FALM)). For
                Malaysian Cases, the copyright solely belongs to Malaysian
                Judiciary (e-court kehakiman).
              </em>
            </p>
          </template>
        </div>
      </div>

      <div class="col">
        <h4
          v-if="full_content !== 'not available yet'"
          class="text-blue text-center mt-4"
        >
          <b>{{ "Case Summary & LawBot" }}</b>
        </h4>

        <div
          style="height: 726px;"
          class="card border shadow-sm p-3 rounded mt-2"
        >
          <div class="d-flex mb-3 justify-content-between">
            <div class="tabs__folderTabs">
              <button
                @click="selectedTab = 'caseDocument'"
                style="margin-right: .5rem;"
                :class="{
                  folderTab: selectedTab === 'caseDocument',
                  active: selectedTab === 'caseDocument',
                }"
              >
                <span class="folderTab__title">{{ "Case Summary" }}</span>
              </button>
              <button
                v-if="permissions.includes('nexlaw.qbot')"
                @click="selectedTab = 'qBot'"
                :class="{
                  folderTab: selectedTab === 'qBot',
                  active: selectedTab === 'qBot',
                }"
              >
                <span class="folderTab__title">
                  {{ "LawBot" }}
                  <b-icon
                    icon="stars"
                    class="mx-1"
                    font-scale="1.3"
                    :animation="selectedTab !== 'qBot' ? 'fade' : 'none'"
                  />
                </span>
              </button>
            </div>

            <div
              v-if="selectedTab === 'caseDocument'"
              class="d-flex align-items-center"
            >
              <span
                v-b-tooltip.hover.top="{ variant: 'secondary' }"
                title="Copy"
                @click="copyContent"
                class="cursor-pointer material-icons-outlined icon-blue mx-4"
              >
                content_copy
              </span>

              <div class="form-check form-switch">
                <input
                  class="form-check-input float-right"
                  type="checkbox"
                  role="switch"
                  v-model="checked"
                />
                <label
                  class="form-check-label text-blue"
                  v-b-tooltip.hover.top="{ variant: 'secondary' }"
                  title="Click To Show NER Tags"
                >
                  {{ "NER" }}
                </label>
              </div>
            </div>
          </div>

          <div
            v-if="selectedTab === 'caseDocument'"
            class="row summary_card"
          >
            <div class="col">
              <div class="my-3">
                <b class="text-blue">{{ "Brief Summary" }}</b>
                <span
                  v-b-tooltip.hover.top="{ variant: 'secondary' }"
                  :title="isSpeaking ? 'Pause' : 'Audio Summary'"
                  @click="toggleSpeech(content_original)"
                  class="float-right cursor-pointer material-symbols-outlined icon-blue"
                >
                  {{ isSpeaking ? "pause" : "text_to_speech" }}
                </span>
              </div>

              <p
                v-if="checked"
                id="ner_content"
                class="ner_content"
                v-html="content"
              ></p>
              <p v-else class="ner_content">{{ content_original }}</p>

              <div v-if="full_content !== 'not available yet'">
                <b class="text-blue mt-4">{{ "Key Points" }}</b>
                <div
                  class="row border-bottom m-2"
                  v-for="(key_point, index) in key_points_original"
                  :key="'key_points' + index"
                >
                  <div class="col-1">
                    <p class="ner_content float-left">
                      <b>{{ index + 1 }}</b>
                    </p>
                  </div>
                  <div class="col">
                    <p
                      v-if="checked"
                      class="ner_content"
                      id="ner_content"
                      v-html="key_points[index]"
                    ></p>
                    <p v-else class="ner_content">{{ key_point }}</p>
                  </div>
                  <div class="col-1">
                    <span
                      v-b-tooltip.hover.left="{ variant: 'secondary' }"
                      :title="
                        buttonStates[index].isSpeaking ? 'Pause' : 'Audio'
                      "
                      @click="toggleSpeech(key_point, index)"
                      class="float-right cursor-pointer material-symbols-outlined icon-blue mt-1"
                    >
                      {{
                        buttonStates[index].isSpeaking
                          ? "pause"
                          : "text_to_speech"
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="selectedTab === 'qBot'"
            class="card border-0 card_question"
          >
            <div class="chat__box" id="messages" ref="messages1">
              <div
                v-for="(message, idx) in chatMessages"
                :key="idx"
                :class="message.class_1"
              >
                <div :class="message.class_1">
                  <div :class="message.class_3">
                    <span
                      v-html="message.text
                      .replace(/\\n/g, '\n') // Convert literal '\n' to actual newline
                      .replace(/^\s*#+\s*(.*?)\s*$/gm, '<br><b>$1</b><br>') // Make lines starting with ### or #### bold
                      .replace(/\n/g, '<br>') // Replace actual newline characters with <br>
                      .replace(/\n+/g, '<br>') // Replace multiple actual newlines with a single <br>
                      .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>') // Convert text between ** to <b>
                      .replace(/\*/g, '') // Remove any remaining asterisks (single)
                      .replace(/\\/g, '<br>') // Replace backslashes with <br>
                    "></span>
                  </div>
                </div>
              </div>

              <div v-if="loadAnswer" class="chat__incoming">
                <div class="chat__bubble__wrapper-incoming">
                  <div class="chat__bubble-incoming animated-background-incoming">
                    <div
                      style="transform: scale(0.7)"
                      class="circles-to-rhombuses-spinner"
                    >
                      <div class="circle"></div>
                      <div class="circle"></div>
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              class="chat__suggestion"
              v-for="(question, index) in default_questions.slice(0, 3)"
              :key="index"
              @click="chooseMessage(question, index)"
              :disabled="loadAnswer || question.displayed"
            >
              <span>{{ question.text }}</span>
            </button>

            <div class="chat__input">
              <input
                :disabled="loadAnswer"
                @keyup.enter="sendNewMessage"
                type="text"
                v-model="NewText"
                placeholder="Ask Questions about the Case..."
              />
              <button
                v-show="NewText.length > 0"
                @click="sendNewMessage"
                :disabled="loadAnswer"
                class="btn-send"
              >
                <span class="material-icons-sharp">arrow_circle_right</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h4 class="text-blue text-center mt-4">
      <b>{{ "Annotations" }}</b>
    </h4>

    <div
      v-if="precedentsLoader < 100"
      class="load-card card mt-2"
    >
      <PageLoader
        :loading="!Object.keys(annotations).length"
        :duration="parseFloat(precedentsLoader.toFixed(2))"
        message="generating"
      />
    </div>

    <div
      v-else-if="!Object.keys(annotations).length"
      class="card"
    >
      <div class="empty-placeholder">
        <img
          class="mb-3"
          src="@/assets/noData.png"
          alt="No Data Image"
        />
        <p class="text-center text-blue font-weight-bold">
          {{ "No Annotation Found" }}
        </p>
      </div>
    </div>

    <template v-else>
      <div
        v-for="(KEY, index) in Object.keys(annotations)"
        :key="index"
        class="accordion mt-2"
        role="tablist"
      >
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <button
              v-b-toggle="'annotation-' + index"
              :class="[
                'accordion-header px-3 py-0 d-flex align-items-center justify-content-between',
                { 'cursor-default': !annotations[KEY].caselaw.length },
              ]"
            >
              <b>
                <!-- <a
                  v-if="annotations[KEY].link.Link"
                  :href="annotations[KEY].link.Link"
                  target="_blank"
                  ><u>{{ KEY.replaceAll('"', "") }}</u></a
                > -->
                <p class="d-inline mb-0">
                  {{ KEY.replaceAll('"', "") }}
                </p>

                <span style="color: rgb(13, 110, 253)">
                  [{{ annotations[KEY].caselaw.length }}
                  case{{ annotations[KEY].caselaw.length ? "s" : "" }}]
                </span>
              </b>

              <div
                v-if="annotations[KEY].caselaw.length"
                style="padding-left: 10px"
              >
                <span class="material-icons-outlined arrow-icon">
                  expand_less
                </span>
              </div>
            </button>
          </b-card-header>
          <b-collapse
            v-if="annotations[KEY].caselaw.length"
            :id="'annotation-' + index"
            accordion="annotation-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <p>
                <b>Cases applying this legislation:</b>
              </p>

              <div class="card mb-3">
                <table class="annotation-table mx-3">
                  <thead>
                    <th scope="col" width="5%">ID</th>
                    <th scope="col" width="35%">Case</th>
                    <th scope="col" class="text-center" width="15%">
                      Judgement Date
                    </th>
                    <th scope="col" class="text-center" width="15%">
                      Country
                    </th>
                    <th scope="col" class="text-center" width="20%">Court</th>
                    <th scope="col" width="10%">Action</th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(obj, o_index) in annotations[KEY].caselaw"
                      :key="o_index"
                    >
                      <td class="font-15" width="5%">{{ o_index + 1 }}</td>
                      <td class="font-15" width="35%">{{ obj.Name }}</td>
                      <td class="text-center font-15" width="15%">
                        {{ obj.Date }}
                      </td>
                      <td class="text-center font-15" width="15%">
                        {{ obj.Jurisdiction }}
                      </td>
                      <td class="text-center font-15" width="20%">
                        {{ obj.Court == "Not Stated" ? "N/A" : obj.Court }}
                      </td>
                      <td class="font-15" width="10%">
                        <button
                          class="btn btn-primary"
                          @click="openAnnotation(index, o_index, obj.Link)"
                        >
                          {{ "Open" }}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </template>

    <h4 class="text-blue text-center mt-4">
      <b>{{ "Case History" }}</b>
    </h4>

    <div
      v-if="caseHistoryLoading"
      class="load-card card mt-2"
    >
      <PageLoader
        :loading="caseHistoryLoading"
        :duration="-1"
        message="generating"
      />
    </div>

    <div
      v-else-if="caseHistory.length == 0"
      class="card"
    >
      <div class="empty-placeholder">
        <img
          class="mb-3"
          src="@/assets/noData.png"
          alt="No Data Image"
        />
        <p class="text-center text-blue font-weight-bold">
          {{ "No Case History Found" }}
        </p>
      </div>
    </div>

    <div v-else class="card shadow-sm p-3">
      <div
        id="caseHistoryHeading"
        class="card-header"
        data-toggle="collapse"
        data-target="#caseHistoryContent"
        aria-controls="caseHistoryContent"
        aria-expanded="false"
      >
        <b>Main Case</b>
        <span class="material-symbols-outlined arrow">expand_less</span>
      </div>
      <div
        id="caseHistoryContent"
        class="collapse"
        aria-labelledby="caseHistoryHeading"
        data-parent="#filtersAccordion"
      >
        <p class="card p-3" v-html="formatData(mainCase.analysis)"></p>
      </div>
      
      <table class="case-history card p-2 mt-2">
        <thead>
          <th scope="col" widht="30%">Case Details</th>
          <th scope="col" width="35%">Judgement Analysis</th>
          <th scope="col" width="35%">
            Comparison of Legal Principle
            <span
              id="case-history-comparison"
              class="span_badge material-icons-outlined cursor-pointer"
            >
              help_outline
            </span>
            <b-tooltip
              target="case-history-comparison"
              triggers="hover"
              placement="righttop"
              variant="secondary"
            >
              The main case is referred to as Judgement A and the History is referred to as Judgement B
            </b-tooltip>
          </th>
        </thead>
        <tbody style="max-height: 70vh;">
          <tr
            v-for="(ch, id) in caseHistory"
            :key="id"
          >
            <td width="30%">
              <b>Case Name</b>
              <p><a :href="ch.Link" target="_blank">{{ ch.Name }}</a></p>
              <b>Date</b>
              <p>{{ ch.Date | formatDateLoc }}</p>
              <b>Court</b>
              <p>{{ ch.Court }}</p>
            </td>
            <td width="35%" v-html="formatData(ch.analysis)"></td>
            <td width="35%" v-html="formatData(ch.comparison)"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PageLoader from "../../components/PageLoader.vue";
import CaseSearch from "@/store/CaseSearch.js";
import { Authenticate, escapeRegExp, loopUpdate, sanitizeHTML, ExportData, ExportDataPDF } from "../../store/utils";

export default {
  components: {
    PageLoader,
  },

  data() {
    return {
      permissions: localStorage.permissions,
      NewText: "",
      loadAnswer: false,
      selectedTab: "caseDocument",
      chatMessages: [],
      default_questions: [
        { text: "What is the ratio decidendi of the case?", displayed: false },
        {
          text: "What are the relevant obiter dicta of the case?",
          displayed: false,
        },
        {
          text: "What is the key legal principle or rule that the court relied on in reaching its decision?",
          displayed: false,
        },
        {
          text: "Can you identify the specific reasoning provided by the court that led to the judgment in this case?",
          displayed: false,
        },
        {
          text: "How does the court's decision in this case impact future cases and legal interpretations?",
          displayed: false,
        },
        {
          text: "What factors influenced the court's interpretation of the law and application to the facts of this case?",
          displayed: false,
        },
        {
          text: "Are there any dissenting opinions or conflicting views among the judges that need to be considered?",
          displayed: false,
        },
        {
          text: "What implications does this judgment have for similar cases or legal issues in the future?",
          displayed: false,
        },
      ],
      decDate: "",
      caseID: 0,
      topic: "",
      precedentsLoader: 0,
      all_precedents: [],
      pageLoading: true,
      case_info: "",
      case_file: "",
      content_to_copy: "",
      content_to_export: "",
      content_to_export_without_ner: "<b>Judgement Info</b>",
      precedents: [],
      content: "",
      exports_items: [
        { name: "PDF", icon: "picture_as_pdf" },
        { name: "DOCUMENT", icon: "description" },
      ],
      status: false, // Checkbox status for "Export with NER Tags"
      pdf_status: [],
      checked: false,
      ner_list: [],
      entities: [],
      key_points: [],
      key_points_original: [],
      full_content: [],
      isSpeaking: false,
      utterance: null,
      buttonStates: [],
      colors: {
        LAWYER: "#7EDFFF",
        COURT: "#9ACD32",
        JUDGE: "#C6BFFF",
        PETITIONER: "#FF7E7E",
        RESPONDENT: "#FFA500",
        CASE_NUMBER: "#BCC2D8",
        GPE: "#87CEFA",
        DATE: "#C1FFC1",
        ORG: "#FFDD7F",
        STATUTE: "#F5DEB3",
        WITNESS: "#FFFF8F",
        PRECEDENT: "#CBE3E2",
        PROVISION: "#F4A460",
        OTHER_PERSON: "#CBCBCB",
      },
      full_form: {
        LAWYER: "lawyer",
        COURT: "court",
        JUDGE: "judge",
        PETITIONER: "petitioner",
        RESPONDENT: "respondent",
        CASE_NUMBER: "Case number",
        GPE: "Countries, cities, state",
        DATE: "date",
        ORG: "organisation",
        STATUTE: "statute",
        WITNESS: "witness",
        PRECEDENT: "precedent",
        PROVISION: "provision",
        OTHER_PERSON: "People names",
      },
      NerTagExplanation:
        "*NER = Natural Language Processing Technique that identifies and classifies named entities from the case",
      PleaseWaitText:
        "Please wait a moment while we process it. This may take some time depending on the size the file and your internet connection speed.",
      content_original: "",
      ner_final: [],

      // Annotations
      annotations: {},

      // Case History
      caseHistoryLoading: true,
      mainCase: null,
      caseHistory: [],

      loop: false,
    };
  },

  methods: {
    formatData(data) {
      return data
        .replaceAll("\n", "<br>")
        .replace(
          /\*\*[\s\S]*?\*\*/g,
          m => m.replace("**", "<b>").replace("**", "</b>")
        );
    },

    getAnnotations(obj, key, lenghtFlag = null) {
      return lenghtFlag
        ? obj[`"${key}"`]?.length ?? 0
        : obj[`"${key}"`];
    },

    initializeUtterance(speechText, index = null) {
      const target = index == null ? this : this.buttonStates[index];

      this.utterance = new SpeechSynthesisUtterance(speechText);
      this.utterance.onstart = () => {
        target.isSpeaking = true;
      };
      this.utterance.onend = () => {
        speechSynthesis.cancel();
        target.isSpeaking = false;
      };
    },

    toggleSpeech(text, index) {
      const target = index == null ? this : this.buttonStates[index];

      if (!target.isSpeaking) {
        if (this.utterance?.text ?? null === text) {
          try {
            speechSynthesis.resume();
            target.isSpeaking = true;
          } catch (error) {
            this.initializeUtterance(text, index);
            speechSynthesis.speak(this.utterance);
          }
        } else {
          speechSynthesis.cancel();
          target.isSpeaking = false;
          this.initializeUtterance(text, index);
          speechSynthesis.speak(this.utterance);
        }
      } else {
        speechSynthesis.pause();
        target.isSpeaking = false;

        this.utterance.onpause = () => {
          target.isSpeaking = false;
          this.utterance.onpause = null;
        };
      }
    },

    chooseMessage(message, index) {
      this.loadAnswer = true;
      this.chatMessages.push({
        text: message.text,
        class_1: "chat__outgoing",
        class_2: "chat__bubble__wrapper-outgoing",
        class_3: "chat__bubble-outgoing animated-background-outgoing",
        new: false,
      });

      // Update the displayed property of the chosen question
      this.default_questions[index].displayed = true;

      var obj = {
        question: message.text,
        mask: "false",
      };

      // console.log("Passed into API", obj);
      CaseSearch.sendQuestion("jurisdiction", this.$route.params.id, obj)
        .then(() => {
          this.default_questions.splice(
            this.default_questions.findIndex((x) => x.text === message.text),
            1
          );
          this.loadData();
        })
        .catch((error) => {
          console.log(error);
          this.loadAnswer = false;
        });

      var div = document.getElementById("messages");
      div.scrollTop = div.scrollHeight;

      this.$nextTick(() => {
        var messageDisplay = this.$refs.messages1;
        messageDisplay.scrollTop = messageDisplay.scrollHeight;
      });
    },

    loadData(flag = null) {
      const InMessage = {
        class_1: "chat__incoming",
        class_2: "chat__bubble__wrapper-incoming",
        class_3: "chat__bubble-incoming animated-background-incoming",
        new: false,
      };
      const OutMessage = {
        class_1: "chat__outgoing",
        class_2: "chat__bubble__wrapper-outgoing",
        class_3: "chat__bubble-outgoing animated-background-outgoing",
        new: false,
      };

      const me = this;
      function processData(data) {
        const DATA = data.data.data;

        // process all data if `flag` is true
        if (flag) {
          me.caseID = DATA.id;
          me.decDate = DATA.date;
          me.topic = DATA.topic;
          me.case_file = DATA?.content ?? me.case_file;

          me.case_info = JSON.parse(
            DATA.values.find((el) => el.key === "basic_details").value
          )[0];

          for (const [key, value] of Object.entries(
            JSON.parse(DATA.values.find((el) => el.key === "ner").value)
          )) {
            me.ner_list.push({
              Entities: key,
              abbreviation: value,
            });
          }

          me.content_original = JSON.parse(
            DATA.values.find((el) => el.key === "brief_summary").value
          );
          me.content = me.content_original;

          me.key_points_original = JSON.parse(
            DATA.values.find((el) => el.key === "key_points").value
          );

          me.key_points = me.key_points_original.map((element) => {
            return me.ner_list.reduce((acc, element_ner) => {
              const escapedEntities = escapeRegExp(element_ner.Entities);
              return acc.replace(
                new RegExp(escapedEntities, "g"),
                `<span class="ner_badge ${element_ner.abbreviation}">${element_ner.Entities}</span>`
              );
            }, element);
          });

          me.buttonStates = me.key_points_original.map(() => ({
            isSpeaking: false,
          }));

          me.full_content = DATA.full_content;
          if (me.full_content.type === "html") {
            me.full_content.value = sanitizeHTML(me.full_content.value);
          }

          me.ner_list.forEach(({ Entities, abbreviation }) => {
            me.content = me.content.replaceAll(
              new RegExp(escapeRegExp(Entities), "g"),
              `<span class="ner_badge ${abbreviation}"> ${Entities} </span>`
            );
          });

          const categorizedData = me.ner_list.reduce((result, item) => {
            // Check if the category exists in the result
            if (!result[item.abbreviation]) {
              result[item.abbreviation] = [];
            }
            // Push the item into the corresponding category
            result[item.abbreviation].push(item.Entities);
            return result;
          }, {});
          me.ner_final = Object.entries(categorizedData).map(
            ([abbreviation, Entities]) => {
              const full_form = me.full_form[abbreviation];
              return {
                Color: abbreviation,
                abbreviation,
                full_form,
                Entities,
              };
            }
          );

          me.content_to_export_without_ner += `<br><br><b>Brief Summary</b><br>${me.content_original}<br><br><b>Key Points</b>`;
          me.content_to_export_without_ner = me.key_points_original
            .map((element, index) => {
              return `${index + 1}) ${element}`;
            })
            .join("<br>");
          me.content_to_export_without_ner += `<br><br><b>Precedents</b>`;
          me.content_to_export_without_ner += me.precedents.join("<br>");

          me.content_to_export = me.content_to_export_without_ner;
          me.content_to_export += `<br><br><b>NER</b>`;
          me.content_to_export += me.ner_list
            .map((element) => `${element.Entities} : ${element.abbreviation}`)
            .join("<br>");

          DATA.questions.forEach((element) => {
            if (element.answer.length != 0) {
              me.default_questions.splice(
                me.default_questions[
                  me.default_questions.findIndex((x) => x === element.question)
                ],
                1
              );

              OutMessage.text = element.question;
              me.chatMessages.push(OutMessage);

              try {
                JSON.parse(element.answer).forEach((answer) => {
                  InMessage.text = answer;
                  me.chatMessages.push(InMessage);
                });
              } catch (error) {
                InMessage.text = element.answer;
                me.chatMessages.push(InMessage);
              }
            }
          });

          if (
            DATA.questions.length != 0 &&
            DATA.questions[DATA.questions.length - 1].answer == null
          ) {
            me.loadAnswer = true;
            me.loop = true;

            OutMessage.text = DATA.questions[DATA.questions.length - 1].question;
            me.chatMessages.push(OutMessage);
          }

          if (me.chatMessages.length == 0) {
            InMessage.text = "You may ask question about the case here🔎"
            me.chatMessages.push(InMessage);
          }

          me.pageLoading = false;
        }

        // update precedent & annotation
        me.precedentsLoader = DATA.PrecedentsCompletedPerCentage;
        me.all_precedents = DATA.precedents;

        me.annotations =
          DATA.values.find((el) => el.key === "caselaw")?.value ?? {};
        if (Object.keys(me.annotations).length > 0) {
          me.annotations = JSON.parse(me.annotations);
        }

        me.precedents = JSON.parse(
          DATA.values.find((el) => el.key === "precedents").value
        );

        // update case history
        me.caseHistoryLoading = false;
        me.caseHistoryCompletedPerCentage = DATA.caseHistoryCompletedPerCentage;
        me.mainCase = JSON.parse(
          DATA.values.find(el => el.key === "main_case")?.value ?? '{}'
        )
        me.caseHistory = JSON.parse(
          DATA.values.find(el => el.key === "case_history")?.value ?? '[]'
        )

        if (me.precedentsLoader < 100 || me.caseHistoryCompletedPerCentage < 100) me.loop = true;

        // update lawbot
        if (!flag) {
          const questions = DATA.questions,
                last_q = questions[questions.length - 1];

          if (!last_q) {

          } else if (last_q.answer) {
            try {
              JSON.parse(last_q.answer).forEach(ans => {
                InMessage.text = ans;
                me.chatMessages.push({...InMessage});
              })
            } catch(e) {
              InMessage.text = last_q.answer;
              me.chatMessages.push({...InMessage});
            }

            me.loadAnswer = false;
          } else {
            me.loop = true;
          }
        }

        flag = false;
      }

      loopUpdate(
        this,
        CaseSearch.OpenSummary,
        this.$route.params.id,
        processData,
        false,
        5000
      );
    },

    sendNewMessage() {
      if (this.NewText.length != 0) {
        this.loadAnswer = true;
        this.chatMessages.push({
          text: this.NewText,
          class_1: "chat__outgoing",
          class_2: "chat__bubble__wrapper-outgoing",
          class_3: "chat__bubble-outgoing animated-background-outgoing",
          new: false,
        });

        var obj = {
          question: this.NewText,
          mask: "false",
        };
        this.NewText = "";

        // console.log("Passed into API", obj);
        CaseSearch.sendQuestion("jurisdiction", this.$route.params.id, obj)
          .then(() => {
            Authenticate(this);
            this.loadData();
          })
          .catch((error) => {
            this.loadAnswer = false;
            console.log(error);
          });

        var div = document.getElementById("messages");
        div.scrollTop = div.scrollHeight;

        this.$nextTick(() => {
          var messageDisplay = this.$refs.messages1;
          messageDisplay.scrollTop = messageDisplay.scrollHeight;
        });
      }
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const chatContainer = this.$refs.messages1;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    },

    exportData() {
      if (this.pdf_status.length === 0) {
        this.$toast.error("Kindly select a format to export");
        return;
      }

      var status = ExportData(
        this.status
          ? this.content_to_export
          : this.content_to_export_without_ner,
        [
          this.pdf_status.find((el) => el.name === "DOCUMENT"),
          this.pdf_status.find((el) => el.name === "PDF"),
        ]
      );

      if (status[0]) {
        this.$toast.success("Successfully downloaded Document");
      }
      if (status[1]) {
        this.$toast.success("Successfully downloaded PDF");
      }

      this.pdf_status = [];
    },

    copyContent() {
      navigator.clipboard.writeText(this.content_to_copy);
      this.$toast.success("Copied");
    },

    viewPrecedentReport() {
      this.$router.push({
        name: "CaseSearchPrecedentReport",
        params: {
          id: this.caseID,
        },
      });
    },

    openAnnotation(id2, id3) {
      this.$router.push({
        name: "CaseSearchAnnotation",
        params: {
          id: this.$route.params.id,
          id2, id3,
        },
      });
    },

    ExportData() {
      ExportDataPDF(this.full_content.value);
      this.$toast.success('Successfully downloaded PDF');
    }
  },

  created() {
    this.loadData(true);
  },

  destroyed() {
    clearTimeout(this.loop);
  },
};
</script>

<style scoped>
.icon-export-blue {
  color: #094890;
  font-size: 2rem;
}

.ner_badge {
  border-radius: 10px;
  border: none;
  color: white;
  padding: 0.5rem;
}

.ner_content ::v-deep .ner_badge {
  border-radius: 10px;
  border: none;
  padding: 0.5rem;
}

.ner_content ::v-deep .badge_color {
  background: #fda355;
}

.ner_content {
  line-height: 2rem;
}

.popover {
  max-width: 500px !important;
}

.ner_content ::v-deep .LAWYER,
.table .LAWYER {
  background: #7edfff;
}

.ner_content ::v-deep .COURT,
.table .COURT {
  background: #9acd32;
}

.ner_content ::v-deep .JUDGE,
.table .JUDGE {
  background: #c6bfff;
}

.ner_content ::v-deep .PETITIONER,
.table .PETITIONER {
  background: #ff7e7e;
}

.ner_content ::v-deep .RESPONDENT,
.table .RESPONDENT {
  background: #ffa500;
}

.ner_content ::v-deep .CASE_NUMBER,
.table .CASE_NUMBER {
  background: #bcc2d8;
}

.ner_content ::v-deep .GPE,
.table .GPE {
  background: #87cefa;
}

.ner_content ::v-deep .DATE,
.table .DATE {
  background: #c1ffc1;
}

.ner_content ::v-deep .ORG,
.table .ORG {
  background: #ffdd7f;
}

.ner_content ::v-deep .STATUTE,
.table .STATUTE {
  background: #f5deb3;
}

.ner_content ::v-deep .WITNESS,
.table .WITNESS {
  background: #ffff8f;
}

.ner_content ::v-deep .PRECEDENT,
.table .PRECEDENT {
  background: #cbe3e2;
}

.ner_content ::v-deep .PROVISION,
.table .PROVISION {
  background: #f4a460;
}

.ner_content ::v-deep .OTHER_PERSON,
.table .OTHER_PERSON {
  background: #cbcbcb;
}

.table_card {
  max-height: 500px !important;
  overflow-x: scroll;
}

.popUp_card {
  max-height: 600px !important;
  overflow-x: hidden;
  overflow-y: scroll;
}

.summary_card {
  max-height: 639px !important;
  overflow-x: hidden;
  overflow-y: scroll;
}

.key_Card {
  max-height: 726px !important;
  overflow-x: scroll;
  overflow-y: scroll;
}

.table {
  --bs-table-bg: none;
  border: none;
  background: white;
  table-layout: fixed;
  font-size: 14px;
}

tbody {
  display: block;
  max-height: 500px;
  overflow-y: scroll;
}

thead {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border-bottom: 0.1vw solid #ddd !important;
  background: white;
}

tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border: none;
  background: white;
}

td {
  padding: 0.5rem 0px;
  border-bottom: 0.001px solid #ddd;
  border-top: none;
  vertical-align: top;
}

th {
  border: none;
  color: #094890;
}

.form-check-input:checked {
  border-color: #094890;
  background-color: #094890;
}

.form-switch .form-check-input {
  box-shadow: #094890;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23094890'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:focus:checked {
  box-shadow: #094890;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  box-shadow: #094890;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:focus {
  box-shadow: #094890;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23094890'/%3e%3c/svg%3e");
}

/* chatBot Css */
.animated-background-outgoing {
  background: linear-gradient(
    45deg,
    #012741,
    #004877,
    #006bb3,
    #0866a5,
    #0976be
  );
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.animated-background-incoming {
  background: linear-gradient(
    45deg,
    #cccaca,
    #bcbebe,
    #d4cece,
    #d3d4d4,
    #f1f1f1
  );
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.card_bot_question {
  height: 700px;
  overflow-y: scroll;
}

.card_question {
  height: 639px !important;
}

.chat__box {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  height: 100%;
  overflow-y: scroll;
}

.chat__incoming {
  margin-right: auto;
  margin-top: 0.5rem;
}

.chat__bubble__wrapper-incoming {
  margin-right: auto;
  margin-bottom: 1rem;
}

.chat__bubble-incoming {
  padding: 0.3rem 1rem;
  background-color: #f1f1f1;
  border-radius: 30px;
  border-bottom-left-radius: 0px;
  margin-right: 3rem;
  margin-left: 0.5rem;
}

.chat__bubble-incoming span {
  color: #333333;
}

.chat__outgoing {
  margin-left: auto;
  margin-top: 0.5rem;
}

.chat__bubble__wrapper-outgoing {
  margin-left: auto;
  margin-bottom: 1rem;
}

.chat__bubble-outgoing {
  padding: 0.3rem 1rem;
  background-color: #86d4f8;
  border-radius: 30px;
  border-bottom-right-radius: 0px;
  margin-left: 3rem;
}

.chat__bubble-outgoing span {
  color: #fff;
}

/* chat suggestions and input */
.chat__suggestion {
  display: flex;
  border: 2px solid #dddddd;
  border-radius: 20px;
  padding-left: 10px;
  height: auto;
  margin-bottom: 0.5rem;
}

.chat__suggestion:hover {
  background-color: #f1f1f1;
  transition: 300ms ease-out;
}

.chat__suggestion span {
  text-align: left;
  padding-top: 0.2rem;
}

.chat__input {
  display: flex;
  border: 2px solid #0058a2;
  border-radius: 20px;
  padding-left: 10px;
}

.chat__input input {
  border: none;
  border-radius: 20px;
  height: 40px;
  width: 100%;
  box-shadow: none;
  outline: none;
}

.chat__input .btn-send {
  padding: 0;
  margin: 0;
  height: 38px;
}

.chat__input .btn-send span {
  color: #0058a2;
  font-size: 40px;
  transition: 300ms ease-out;
}

.chat__input .btn-send:hover span {
  color: #004278;
}

.accordion-label {
  cursor: pointer;
  display: block;
}

.accordion-icon {
  transition: transform 0.3s;
}

#nerAccordionHeading[aria-expanded="true"] .accordion-icon {
  transform: rotate(-180deg);
}

.card-header {
  padding: 0rem 0rem !important;
  padding-top: 1rem !important;
  margin-bottom: 0 !important;
  color: var(--bs-card-cap-color) !important;
  background-color: #ffffff !important;
  border-bottom: 0px solid #ffffff !important;
}

.align-content-center {
  flex-wrap: inherit;
}

@media screen and (max-width: 767.98px) {
  .minH {
    min-height: 275px;
  }
}

.annotation-table {
  background: white;
  table-layout: fixed;
  width: auto !important;
}

.container_margin {
  padding: 2rem;
}

header.card-header {
  padding: 0px !important;
}

.accordion-header {
  background-color: transparent;
  width: 100%;
  height: 48px;
  border-color: transparent;
  color: black;
  text-align: left;
}
.accordion-header:hover {
  background-color: transparent;
}

.accordion-header a {
  text-decoration: none;
  color: black;
}

.accordion-header a:hover {
  text-decoration: underline;
  color: var(--primary);
}

.accordion-header[aria-expanded="true"] .arrow-icon {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.accordion-header[aria-expanded="false"] .arrow-icon {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.arrow-icon {
  display: none;
  display: block;
  -webkit-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  color: black;
}

.card-body {
  padding-bottom: 0px;
}

.accordion-header[aria-expanded="true"] {
  border-bottom: solid 1px lightgray;
}

.cursor-default {
  cursor: default;
}

.load-card {
  height: 400px;
}

.empty-placeholder {
  width: max-content;
  height: max-content;
  margin: 50px auto 50px auto;
}

.case-history th span {
  margin: 2px 0px;
  font-size: 16px;
}

.case-history td {
  padding: 5px;
  border-right: 1px solid lightgrey;
  font-size: 14px !important;

  &:last-child {
    border-right: 0px;
  }
}

#caseHistoryHeading {
  padding: 0px 10px !important;
  height: 35px;
  border: 1px solid lightgrey;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5 !important;
  width: 100%;

  transition: all .4s ease;
  span {
    transition: all .4s ease;
  }

  &[aria-expanded=true] {
    border-radius: 8px 8px 0px 0px;
  }
}

#caseHistoryContent .card {
  border-radius: 0px 0px 8px 8px !important;
}

.card-header[aria-expanded=false] .arrow {
  transform: rotate(-180deg);
}
</style>
